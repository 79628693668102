import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Auth from '@okta/okta-vue'
import authConfig from '../AuthConfig'

Vue.use(VueRouter)

Vue.use(Auth, authConfig)

const routes = [
  { path: '/', name: 'home', component: Home, meta: { requiresAuth: false } },
  { path: '/implicit/callback', component: Auth.handleCallback() },
  { path: '/signin', name: 'signin', component: () => import('../views/auth/SignIn') },
  {
    path: '/user',
    component: () => import('../views/auth/UserProfile.vue'),
    meta: { requiresAuth: true },
    children: [
      { path: 'password', name: 'changePassword', component: () => import('../views/auth/ChangePassword'), meta: { requiresAuth: true } },
      { path: 'profile', name: 'userEditProfile', component: () => import('../views/auth/UpdateProfile'), meta: { requiresAuth: true } }
    ]
  },
  { path: '/journal', name: 'journal', component: () => import('../views/journal/Journal.vue'), meta: { requiresAuth: true } },
  { path: '/shopping/:listId?', name: 'shopping', component: () => import('../views/Shopping.vue'), meta: { requiresAuth: true } },
  {
    path: '/addresses',
    component: () => import('../views/contacts/Addresses.vue'),
    meta: { requiresAuth: true },
    children: [
      { path: '', name: 'address-list', component: () => import('../views/contacts/AddressList.vue') },
      { path: 'new', name: 'create-address', component: () => import('../views/contacts/EditAddress.vue') },
      { path: 'edit/:id', name: 'edit-address', component: () => import('../views/contacts/EditAddress.vue') }
    ]
  },
  {
    path: '/meal-plans',
    name: 'meal-planning',
    component: () => import('../views/meal-plans/MealPlanning.vue'),
    meta: { requiresAuth: true },
    children: [
      { path: 'planner', name: 'meal-planner', component: () => import('../views/meal-plans/Planner.vue') },
      { path: 'meals', name: 'meals', component: () => import('../views/meal-plans/Meals.vue') },
      { path: 'meals/new', name: 'add-meal', component: () => import('../views/meal-plans/AddMeal.vue') },
      { path: 'meals/edit/:id', name: 'edit-meal', component: () => import('../views/meal-plans/EditMeal.vue') }

    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active'
})

const onAuthRequired = async (from, to, next) => {
  if (from.matched.some(record => record.meta.requiresAuth) && !(await Vue.prototype.$auth.isAuthenticated())) {
    next({ name: 'home' }) // TODO: Switch to login page when custom login is impl
  } else {
    next()
  }
}

router.beforeEach(onAuthRequired)

export default router
